.blood-req-body {
  font-size: 14px;
}

.blood-requisition-no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
}

.add-requisition-btn-table {
  display: flex;
  padding: 7.427px 11.141px;
  justify-content: center;
  align-items: center;
  gap: 7.427px;
  border-radius: 7.427px;
  background: var(--ehr-secondary-l-8, #d3dce8);
  color: #214f8a;
}

.empty-requisition-msg {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.add-requisition-msg {
  display: flex;
  width: 320px;
  flex-direction: column;
  justify-content: center;
  color: var(--gray-gray-400, #6b7280);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.282px;
}

.blood-req-component {
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 50px;
  background: var(--ehr-secondary-l-9, #e9edf3);
}

.blood-req-component-name {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;
}

.blood-req-component-volume {
  padding: 4px;
}

.st-pending {
  color: #214f8a;
  cursor: pointer;
}

.st-done {
  cursor: pointer;
  color: #11a99a;
}

.blood-grouping-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.in-fld {
  border-radius: 8px;
}

.qmark {
  position: absolute;
  right: 20px;
  top: 42px;
}

.bloodrequisition-min-width {
  min-width: 200px;
}

.Blood_issue_grp {
  background-color: var(--ehr-background, #f2f9f7);
  border-radius: 8px;
  height: 100%;
  position: relative;
  width: 100%;
  min-height: 451px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: flex-start;
}

.Blood_issue_grp .inputbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  height: 80%;
  width: 100%;
}


.Blood_issue_grp .blood-group {
  /* min-width: 220px; */
  padding: 1rem;
}

.bloodrequisition-min-width {
  min-width: 200px;
}

@media only screen and (min-width: 576px) {
  .freez-column {
    position: sticky;
    right: 0;
    z-index: 2;
  }
}

.pg-header {
  display: flex;
  height: 174px;
  width: 174px;
  justify-content: center;
  align-items: center;
}

.Blood_issue_grp .blood-drop {
  height: 170px;
  width: 126px;
}

.Blood_issue_grp .label {
  align-items: flex-start;
  display: inline-flex;
}

.Blood_issue_grp .label-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 0px 4px;
  position: relative;
}

.Blood_issue_grp .label-blood {
  color: var(--Secondary, #214f8a);
  font-family: 'Inter';
  font-size: 85px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Blood_issue_grp .text-wrapper {
  color: var(--gray-gray-300, #9ca3af);
  font-family: "Inter-SemiBold", Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  width: fit-content;
}

@media only screen and (min-width: 576px) {
  .freez-column {
    position: sticky;
    right: 0;
    z-index: 2;
  }
}

.pg-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.blood-request-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.con {
  display: flex;
  justify-content: center;
  align-items: center !important;
}




.Billing-step {
  min-width: 220px;
  padding: 10px 42px 20px 0px;
}

.cc th {
  padding: 12px;
}

.cc td {
  padding: 8px;
}

.pmode-container {
  display: flex;
  width: 100%;
  height: 156px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border-radius: 8px;
  background: #f2f9f7;
}

.pmode-cards-container {
  display: flex;
  width: 170px;
  height: 80px;
  padding: 13px 12.126px 13px 0px;
  align-items: center;
  border-radius: 8px;
  border: 2px solid var(--grey-blue-grey-9, #cfd8dc);
  background: #fff;
}

.pmode-cards-container-active {
  display: flex;
  width: 170px;
  height: 80px;
  padding: 13px 12.126px 13px 0px;
  align-items: center;
  border-radius: 8px;
  background: #fff;
  border-radius: 8px;
  border: 2px solid #214f8a;
  background: #f2f9f7;
}

.pmode-cards-subcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: var(--primary-primary-grey, #1b2124);

  /* Label / L12 - Bold */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 133.333% */
}

.pmode-mode-container {
  margin-top: 32px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.payment-sumary-hding {
  color: var(--primary-black, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  border-bottom: 1px solid rgba(128, 128, 128, 0.379);
}

.payment-sumary-p {
  color: #263238;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.payment-sumary-t {
  border-top: 1px solid rgba(128, 128, 128, 0.379);
  color: var(--primary-black, #000);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
}

.radio-button-chkd input[type="radio"]:checked+label {
  background-color: #76cf9f;
}

.credit_amount {
  display: flex;
  width: 100%;
  height: 156px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #f2f9f7;
}

.restricted {
  cursor: not-allowed;
  opacity: 0.6;
}

.Billing-step-min {
  display: none;
}

.Billing-Step-Form {
  display: flex;
}

@media only screen and (max-width: 576px) {
  .Billing-Step-Form {
    display: flex;
    flex-direction: column;
  }

  .Billing-step-min {
    display: block;
  }

  .Billing-step {
    display: none;
  }
}

.hover-image {
  position: relative;
  margin-left: 8px;
}

.info {
  display: none;
  position: absolute;
  top: 0;
  left: 60%;
  padding: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 4px 24px 10px rgba(0, 0, 0, 0.06);
  z-index: 999;
}

.hover-image:hover .info {
  display: block;
}

.info-label {
  color: #757e83;
}




.cc th {
  padding: 12px;
}

.cc td {
  padding: 8px;
}

.pmode-container {
  display: flex;
  width: 100%;
  height: 156px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border-radius: 8px;
  background: #F2F9F7;
}

.pmode-cards-container {
  display: flex;
  width: 170px;
  height: 80px;
  padding: 13px 12.126px 13px 0px;
  align-items: center;
  border-radius: 8px;
  border: 2px solid var(--grey-blue-grey-9, #CFD8DC);
  background: #FFF;
}

.pmode-cards-container-active {
  display: flex;
  width: 170px;
  height: 80px;
  padding: 13px 12.126px 13px 0px;
  align-items: center;
  border-radius: 8px;
  background: #FFF;
  border-radius: 8px;
  border: 2px solid #214F8A;
  background: #F2F9F7;
}

.pmode-cards-subcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: var(--primary-primary-grey, #1B2124);

  /* Label / L12 - Bold */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 133.333% */
}

.pmode-mode-container {
  margin-top: 32px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.payment-sumary-hding {
  color: var(--primary-black, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  border-bottom: 1px solid rgba(128, 128, 128, 0.379);
}

.payment-sumary-p {

  color: #263238;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.payment-sumary-t {
  border-top: 1px solid rgba(128, 128, 128, 0.379);
  color: var(--primary-black, #000);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 133.333% */
}

.radio-button-chkd input[type="radio"]:checked+label {
  background-color: #76cf9f;
}

.credit_amount {
  display: flex;
  width: 100%;
  height: 156px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #F2F9F7;
}


.restricted {
  cursor: not-allowed;
  opacity: 0.6;
}

.Billing-step-min {
  display: none;
}

.Billing-Step-Form {
  display: flex;
}

@media only screen and (max-width: 576px) {
  .Billing-Step-Form {
    display: flex;
    flex-direction: column;
  }

  .Billing-step-min {
    display: block;
  }

  .Billing-step {
    display: none;
  }
}

.hover-image {
  position: relative;
  margin-left: 8px;
}

.info {
  display: none;
  position: absolute;
  top: 0;
  left: 60%;
  padding: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 4px 24px 10px rgba(0, 0, 0, 0.06);
  z-index: 999;
}

.hover-image:hover .info {
  display: block;
}


.info-label {
  color: #757E83;
}

.secondary-request-text {
  color: var(--gray-gray-400, #6B7280);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.dark-request-text {
  color: var(--primary-primary-grey, #1B2124);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.primary-request-text {
  color: var(--ehr-secondary-d-0, #214F8A);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.cme-2 {
  margin-right: 10px !important;
}

.cms-2 {
  margin-left: 10px !important;
}

.filter-head {
  color: #5E5E5E !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.filter-value {
  color: #444 !important;

  font-family: Inter !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.filter-value-sub {
  color: #444 !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;


}

.done-attachment {
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
  fill: #11A99A !important;
  color: #11A99A !important;
  border-radius: 0 !important;
}

.file-uploader-main-div {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px dashed var(--tints-shades-p-light-50, #7986CB);
  background: var(--ehr-secondary-l-9, #E9EDF3);
}

.file-uploader-sub-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.uploader-typo {
  color: #9CA3AF;
  font-family: Noto Sans;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.uploader-image {
  width: 48px;
  height: 48px;
}

.uploader-typo-sub {
  color: var(--ehr-secondary-d-0, #214F8A);
  font-family: Noto Sans;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}


.requestid-name {
  color: var(--tints-shades-p-light-10, #303f9f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  /* 150% */
  cursor: pointer;
}

.card-as-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  /* background: linear-gradient(90.26deg, #214f89 0.17%, #12bcab 99.77%); */
  /* background: #F2F9F7;   */
  background: #f2f2f2;
  padding: 16px;
  border: '1px solid #ddd';
  border-radius: 8px;
  color:"fff";
  cursor: pointer;
  font-weight: 600;
}
