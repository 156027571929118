table > thead {
  background: #fbfbfb !important;
}

.donor-inventory-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.search-container {
  width: 60% !important;
}

.search-container-tenant {
  width: 30% !important;
}

.select-tenant-dropdown {
  background: #f5f5f5;
  border: 2px solid #cfd8dc;
  border-radius: 8px;
  padding: 0;
  /* width: 50%; */
  max-width: 20% !important;
  height: 100%;
  color: red;
}

.select-input > option {
  background: #f5f5f5;
  /* border: 1px solid #CFD8DC; */
  border-radius: 8px;
  padding: 0;
  color: black;
}

.select-input {
  background: #f5f5f5;
  border-radius: 8px;
  margin: 0;
  color: black;
}

.table_item {
  cursor: pointer;
}

.select-input:focus {
  background: #f5f5f5;
  /* border: 2px solid #CFD8DC; */
  color: black;
}

option:not(:first-of-type) {
  color: black;
}

.search-container > input {
  background: #f5f5f5;
  border: 1px solid #cfd8dc;
  border-radius: 8px;
  /* width: 50%; */
  padding: 0.5rem;
  width: 20rem;
}

.newDataRow {
  width: 100% !important;
}

.addNewData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto !important;
  /* border: 2px solid rebeccapurple; */
}

.addNewData > img {
  border-radius: 0 !important;
  width: 140px !important;
  height: 140px !important;
}

.addNewData > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7.42727px 11.1409px;
  margin: 1rem;

  width: 81.28px;
  height: 37.85px;

  background: #d3dce8;
  border-radius: 7.42727px;
  border: none;
}

.addDataLable {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  color: #6b7280;
  width: 372px;
}

.modalLink {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  text-decoration-line: underline;

  color: #214f8a;

  cursor: pointer;
}

.default-row {
  height: auto;
  transition: all 200ms ease-in-out 0;
}

.hidden-row {
  display: none;
  transition: all 200ms ease-in-out 0;
}

.visible-row {
  transition: all 200ms ease-in-out 0;
  /* display: ; */
}

.chip_div {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  justify-content: flex-start;
}

.inventory_tab tr td {
  align-items: start;
  vertical-align: middle;
}

.inventory_tab tr th {
  align-items: start;
  vertical-align: middle;
}

.chip_custom {
  max-width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  height: 2.5em;
  font-size: 14px;
  line-height: 20px;
  border-radius: 25px;
  background-color: #f1f1f1;
}

.chip_custom_text {
  max-width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  font-size: 14px;
  line-height: 20px;
}

.fit_chip {
  background: #e7f8f7;
  color: #0d8478;
  border-radius: 8px !important;
}

.defered_chip {
  background: #faf0e7;
  color: #dd0a0a;
  border-radius: 8px !important;
}

.danger-blood-stock {
  background: #f5e8e8 !important;
  color: #ad1e1e !important;
  border: 1px solid #fff !important;
}
