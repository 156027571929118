.modal-dialog {
    height: 100% !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.modal-content {
    width: 673px;
}

.modal-header {
    /* border: 2px solid rebeccapurple; */

    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: 1rem !important;
}

.modal-header>img {
    /* border: 2px solid rebeccapurple; */
    cursor: pointer;
    height: 100%;
}

.modal-input-item {
    /* border: 2px solid rebeccapurple; */
    display: flex;
    justify-content: space-between;
}

.modal-title {
    /* border: 2px solid rebeccapurple; */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;

    display: flex;
    align-items: center;

    color: #07101C;
}


.modal-input-item-container {
    /* border: 2px solid rebeccapurple; */
    display: flex;
    justify-content: space-between;
    /* grid-template-columns: 2fr 1fr; */
}

.modal-input-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px dashed #214F8A;
    border-radius: 8px;
}

.modal-input-item {
    display: flex;
    flex-direction: column;
}

.modal-action-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: max-content;
    /* border: 2px solid rebeccapurple; */
}

.modal-action-container > img {
    cursor: pointer;
}

.addNewComponent {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;

    color: #009283;
    cursor: pointer;
}