.detail-grid-ui {
  display: flex;
}

.parent-card-ui {
  width: max-content;
}

.info-ui {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.flex-bag {
  width: 100% !important;
}

.bag-wrap {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
}
