.custom-autocomplete {
  position: relative;
  width: auto;
}

.custom-autocomplete__input {
  width: auto;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.custom-autocomplete__list {
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  list-style: none;
  margin: 4px 0;
  padding: 0;
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 999999;
  pointer-events: auto; /* Ensure the list can be interacted with */
}

.custom-autocomplete__list:hover {
  pointer-events: auto; /* Ensure the list can be interacted with when hovered */
}

.custom-autocomplete__item {
  padding: 8px;
  cursor: pointer;
}

.custom-autocomplete__item:hover {
  background-color: #f0f0f0;
}

.custom-autocomplete__add-item {
  font-weight: bold;
  color: #007bff;
  text-align: center;
}

.custom-autocomplete__add-item:hover {
  background-color: #eaf4ff;
  color: #0056b3;
}
