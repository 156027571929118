.navbar {
  font-weight: 300;
  background: #fff;
  position: fixed !important;
  top: 16px;
  padding-right: 16px !important;
  border-radius: 8px;
  z-index: 999;
  transition: 0.25s ease;
  width: 94% !important;
}

.navbar-menu-wrapper {
  height: 4vh !important;
  min-height: 40px !important;
}

.navbar .navbar-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  list-style-type: none;
}
.nav-full-width{width: 100% !important;padding-left: 16px !important;}
.nav-position{position:fixed !important   ;top : 0 !important}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: initial;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.3125rem;
}
.stretch-card > .card {
  width: 100%;
  min-width: 100%;
}

/* .table thead th {
  border-top: 0;
  border-bottom-width: 1px;
  font-weight: 500;
}
.table td {
  vertical-align: middle;
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
} */

.page-header {
  margin: 0 0 1.5rem;
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
}

.nav-link {
  height: auto;
  line-height: 1;
  border-top: 0;
  padding: 1.25rem 0;
}

.nav-link {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 1.125rem 0;
  color: #fff;
  transition-duration: 0.45s;
  transition-property: color;
  -webkit-transition-property: color;
  cursor: pointer;
}

.sidebar .nav .nav-item .nav-link {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 1.125rem 0;
  color: #fff;
  transition-duration: 0.45s;
  transition-property: color;
  -webkit-transition-property: color;
  cursor: pointer;
}

.nav-link .nav-profile-image {
  width: 44px;
  height: 44px;
}
.nav-link .nav-profile-text {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.nav-item.active {
  background: hsla(0, 0%, 100%, 0.25098039215686274);
  border-radius: 8px;
}

.nav-item {
  padding: 0 0.5rem !important;
  margin: 0.25rem 0 !important;
}

.nav-link {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 1.125rem 0;
  color: #fff;
  transition-duration: 0.45s;
  transition-property: color;
  -webkit-transition-property: color;
  cursor: pointer;
}

.breadcrumb .breadcrumb-item {
  font-size: 0.875rem;
}

.page-title {
  color: #000;
  font-size: 1.5rem;
  margin-bottom: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  color: #374151;
}

.table td,
.table th {
  padding: 0.9375rem;
  vertical-align: top;
  border-top: 1px solid #ebedf2;
}

.layput-nav-item .nav-link {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #fff;
  transition-duration: 0.45s;
  transition-property: color;
  -webkit-transition-property: color;
  cursor: pointer;
}

.layput-nav-item.active {
  background: hsla(0, 0%, 100%, 0.25098039215686274);
  border-radius: 8px;
}

.nav .nav-item {
  padding: 0 0.5rem !important;
  margin: 0.25rem 0 !important;
}

.nav-profile {
  margin-bottom: 5px !important;
}

a {
  text-decoration: none;
  background-color: initial;
}

.nav .nav-profile {
  padding-left: 0px !important;
}

.center_imp {
  justify-content: center !important;
}

.form-check-label {
  font-size: 1rem !important;
  line-height: 1.8 !important;
}

.transition_elemetrn {
  transition: 'width 2s ease-in-out';
}


.react_table_wrapper {
  position: relative;
  overflow-y: hidden !important; 
  /* z-index: 1200; */
}

.react_table_wrapper:hover{
  overflow-y: auto !important;
}

.react_table thead th {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  /* Optional: Add border or box-shadow for better visibility */
  border-bottom: 1px solid #ccc;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.no_data_img {
  width: auto !important;
  height: auto !important;
  border-radius: 1% !important;
}
.ediotr_div {
  background: #f5f5f5;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
}
.ediotr_div iframe {
  display: block !important;
}
/* 
.tox .tox-edit-area::before{
  border: none !important;
} */

.justify-between{
justify-content: space-between;
}
