// stylelint-disable selector-no-qualifying-type

//
// Textual form controls
//

.form-control {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include border-radius($input-border-radius, 0);

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  // @include form-control-focus($ignore-warning: true);

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  &.form-control {
    appearance: none; // Fix appearance for date inputs in Safari
  }
}

select.form-control {
  // Remove select outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $input-color;
  }

  &:focus::-ms-value {
    // Suppress the nested default white text on blue background highlight given to
    // the selected option text when the (still closed) <select> receives focus
    // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
    // match the appearance of the native widget.
    // See https://github.com/twbs/bootstrap/issues/19398.
    color: $input-color;
    background-color: $input-bg;
  }
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: add($input-padding-y, $input-border-width);
  padding-bottom: add($input-padding-y, $input-border-width);
  margin-bottom: 0; // Override the `<label>/<legend>` default
  @include font-size(inherit); // Override the `<legend>` default
  line-height: $input-line-height;
}

.col-form-label-lg {
  padding-top: add($input-padding-y-lg, $input-border-width);
  padding-bottom: add($input-padding-y-lg, $input-border-width);
  @include font-size($input-font-size-lg);
  // line-height: $input-line-height-lg;
}

.col-form-label-sm {
  padding-top: add($input-padding-y-sm, $input-border-width);
  padding-bottom: add($input-padding-y-sm, $input-border-width);
  @include font-size($input-font-size-sm);
  // line-height: $input-line-height-sm;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: $input-padding-y 0;
  margin-bottom: 0; // match inputs if this class comes on inputs with default margins
  @include font-size($input-font-size);
  line-height: $input-line-height;
  color: $input-plaintext-color;
  background-color: transparent;
  border: solid transparent;
  border-width: $input-border-width 0;

  &.form-control-sm,
  &.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }
}

.form-control-sm {
  height: $input-height-sm;
  padding: $input-padding-y-sm $input-padding-x-sm;
  @include font-size($input-font-size-sm);
  // line-height: $input-line-height-sm;
  @include border-radius($input-border-radius-sm);
}

.form-control-lg {
  height: $input-height-lg;
  padding: $input-padding-y-lg $input-padding-x-lg;
  @include font-size($input-font-size-lg);
  // line-height: $input-line-height-lg;
  @include border-radius($input-border-radius-lg);
}

select.form-control {
  &[size],
  &[multiple] {
    height: auto;
  }
}

textarea.form-control {
  height: auto;
}

.form-text {
  display: block;
  margin-top: $form-text-margin-top;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
}

.form-check {
  position: relative;
  display: block;
  // padding-left: $form-check-input-gutter;
}

.form-check-input {
  position: absolute;
  // Use [disabled] and :disabled for workaround https://github.com/twbs/bootstrap/issues/28247
  &[disabled] ~ .form-check-label,
  &:disabled ~ .form-check-label {
    color: $text-muted;
  }
}

.form-check-label {
  margin-bottom: 0; // Override default `<label>` bottom margin
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0; // Override base .form-check
  // Undo .form-check-input defaults and add some `margin-right`.
  .form-check-input {
    position: static;
    margin-top: 0;
    margin-left: 0;
  }
}

// .form-inline {
//   display: flex;
//   flex-flow: row wrap;
//   align-items: center; // Prevent shorter elements from growing to same height as others (e.g., small buttons growing to normal sized button height)

//   // Because we use flex, the initial sizing of checkboxes is collapsed and
//   // doesn't occupy the full-width (which is what we want for xs grid tier),
//   // so we force that here.
//   .form-check {
//     width: 100%;
//   }

//   // Kick in the inline
//   @include media-breakpoint-up(sm) {
//     label {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       margin-bottom: 0;
//     }

//     // Inline-block all the things for "inline"
//     .form-group {
//       display: flex;
//       flex: 0 0 auto;
//       flex-flow: row wrap;
//       align-items: center;
//       margin-bottom: 0;
//     }

//     // Allow folks to *not* use `.form-group`
//     .form-control {
//       display: inline-block;
//       width: auto; // Prevent labels from stacking above inputs in `.form-group`
//       vertical-align: middle;
//     }

//     // Make static controls behave like regular ones
//     .form-control-plaintext {
//       display: inline-block;
//     }

//     .input-group,
//     .custom-select {
//       width: auto;
//     }

//     // Remove default margin on radios/checkboxes that were used for stacking, and
//     // then undo the floating of radios and checkboxes to match.
//     .form-check {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       width: auto;
//       padding-left: 0;
//     }
//     .form-check-input {
//       position: relative;
//       flex-shrink: 0;
//       margin-top: 0;
//       margin-right: $form-check-input-margin-x;
//       margin-left: 0;
//     }

//     .custom-control {
//       align-items: center;
//       justify-content: center;
//     }
//     .custom-control-label {
//       margin-bottom: 0;
//     }
//   }
// }




.form-group {
  margin-bottom: 1rem;
}

.input-group-append,
.input-group-prepend {
  color: $input-placeholder-color;
  width: auto;
  border: none;
  .input-group-text {
    border-color: $border-color;
    padding: 0.575rem 0.75rem;
    color: $input-placeholder-color;
  }
  button {
    padding-top: .3rem;
    padding-bottom: .3rem;
  }
}

.custom-control {
  .custom-control-label {
    line-height: 1.6;
    margin-bottom: 0;
  }
}

.form-control {
  border: 1px solid $border-color;
  font-size: $input-font-size;
}

select {
  &.form-control {
    padding: .4375rem .75rem;
    border: 0;
    outline: 1px solid $border-color;
    color: $input-placeholder-color;
    &:focus {
      outline: 1px solid $border-color;
    }
  }
}

.form-group {
  label {
    font-size: $default-font-size;
    line-height: 1;
    vertical-align: top;
    margin-bottom: .5rem;
  }
  &.has-danger {
    .form-control {
      // border-color: theme-color(danger);
    }
  }
  .file-upload-default {
    visibility: hidden;
    position: absolute;
  }
  .file-upload-info {
    background: transparent;
  }
}
.custom-file {
  .visibility-hidden {
    visibility: hidden;
  }
  .custom-file-label {
    background: $input-bg;;
    border: 1px solid $border-color;
    height: calc(2.25rem + 2px);
    font-weight: normal;
    font-size: 0.875rem;
    padding: 0.625rem 0.6875rem;
    border-radius: 2px;
    &:after {
      background-color: theme-color(primary);
      height: auto;
      @extend .btn-primary;
    }
  }
}
