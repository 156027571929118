.donor_action {
  display: flex !important;
  align-items: center !important;
  margin: 0 !important;
  /* border: 2px solid rebeccapurple !important; */
}

.donor_action > img {
  cursor: pointer;
  border-radius: 0 !important;
  transition: all 0ms 200ms ease-in-out;
  /* border: 2px solid rebeccapurple !important; */
}

.search-container img {
  padding-left: 1rem !important;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .dropdown-menu.navbar-dropdown .dropdown-item {
  padding: 0px 13px;
}

.navbar .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
  font-size: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.detail-grid {
  padding-top: 1rem;
}

.profile-section-item {
  grid-gap: 1rem;
}

/* .table thead th {
    text-align: center !important;
} */
.profile-tab-item {
  white-space: nowrap;
}

.profile-tabs {
  overflow-x: scroll;
}

.main_profile .add_info .info_1 .col_title {
  font-size: 18px !important;
}

.col-1,
.col-2,
.col-3,
.tab-vertical-custom .nav-tabs,
.col-4,
.pills-vertical .nav-pills,
.col-5,
.col-6,
.col-7,
.col-8,
.pills-vertical .tab-content,
.col-9,
.tab-vertical-custom.tab-content,
.col-10,
.col-11,
.col-12,
.col,
.form-wizard .container ol.progtrckr li,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.form-wizard.form-wizard-vertical .container ol.progtrckr,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.form-wizard.form-wizard-vertical .container .content,
.col-md-10,
.col-md-11,
.col-md-12,
.form-wizard.form-wizard-vertical .container > div:not(.content),
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.reg-box {
  padding: 0.8rem !important;
  margin-left: 0rem !important;
}

.reg-box-staff {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border: 1px dashed #214f8a !important;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px;
}

.modal-content {
  margin: 0 auto;
}

.permission {
  width: 100%;
  padding-bottom: 1rem;
}

.permission_access .form-check {
  margin: 0.5rem !important;
}

.permission_access {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 10px;
}

.form-check .form-check-label {
  text-transform: capitalize;
}

.btn-gradient-primary {
  white-space: nowrap;
}

.card .card-body {
  overflow: hidden;
  overflow-y: scroll;
}

.modal-content {
  overflow-x: hidden;
}

.modal-header {
  border: none;
}

.add_blood {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.add_blood label {
  white-space: nowrap;
}

.inventory_btn {
  padding-left: 1rem;
}

.choice-container p {
  font-size: 1rem;
}

.table thead th {
  text-align: left !important;
}

.reg-box {
  border: none;
  /* background-image: url(data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23214F8AFF' stroke-width='2' stroke-dasharray='6 %2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e); */
  /* display: inline-block; */
  padding: 20px;
  border: dashed 6px #000;
  border-image-source: url('/public/assets/images/border.svg') 6 round;
  border-image-slice: 2;
  border-image-repeat: round;
  border-image: url('/public/assets/images/border.svg') 6 round;
  margin: 1.2rem 0px;
}

.reg_bordered {
  display: inline-block;
  padding: 20px;
  /* Fallback dashed border
       - the 4px width here is overwritten with the border-image-width (if set)
       - the border-image-width can be omitted below if it is the same as the 4px here
    */
  border: dashed 8px #000;

  /* Individual border image properties */
  border-image-source: url('/public/assets/images/border.svg');
  border-image-slice: 2;
  border-image-repeat: round;

  /* or use the shorthand border-image */
  border-image: url('/public/assets/images/border.svg') 8 round;
  margin: 0 20px;
}

.inventory_btn {
  margin-left: 1rem !important;
}

.reg-box {
  padding-left: 1rem;
}

.page-title {
  font-size: 20px;
}

.search-container-tenant {
  width: auto !important;
  min-width: 240px;
}

.search-container {
  width: auto;
}

.select-tenant-type {
  width: auto;
  min-width: 240px;
}

.donor-inventory-search {
  flex-wrap: wrap;
  gap: 1rem;
}

.div_label {
  top: -9.5px;
}

.tenant-search-select {
  display: flex;
  align-items: center;
  justify-self: start;
}

.search-container {
  width: auto !important;
}

.main-content {
  overflow: hidden;
}

.dashboard-main-content-new {
  height: 100vh !important;
}

.donor-fit {
  padding: 4px 16px;
  gap: 10px;
  border-radius: 8px;
  background: var(--ehr-primary-l-9, #e7f8f7);
  color: var(--ehr-primary-d-3, #0d8478);
  font-size: 14px;
  font-family: Inter;
  line-height: 24px;
}
.error-fit {
  background: var(--secondary-warning-bg, #faf0e7);
  color: var(--secondary-danger-dark, #dd0a0a);
}

.donor-defered-permanent {
  padding: 4px 16px;
  border-radius: 8px;
  background: var(--secondary-warning-bg, #faf0e7);
  color: var(--secondary-danger-dark, #dd0a0a);
  font-size: 14px;
  font-family: Inter;
  line-height: 24px;
}

.donor-defered-partial {
  padding: 4px 16px;
  border-radius: 8px;
  background: var(--secondary-warning-bg, #faf0e7);
  color: var(--secondary-danger-dark, #f09500);
  font-size: 14px;
  font-family: Inter;
  line-height: 24px;
}

.dnr-no-data-found {
  padding: 2rem 0;
}

.filters-container-div {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.donor-filter-main {
  display: flex;
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
}

.filter-arrow {
  font-weight: 800 !important;
}

.filter-con {
  width: auto;
  margin-bottom: 5px;
}

.fiter-tr th {
  min-width: 160px;
}

.filter-tbody tr td {
  font-size: 14px;
}

.donor-filter-content {
  display: flex;
  padding: 0 8px 0 9px;
  flex-direction: column;
  width: 120px;
}

.donor-filter-content-title {
  color: var(--gray-gray-700, #1f2937);
  font-size: 15px;
  font-family: Inter;
  font-weight: 700;
}

.donor-filter-img {
  display: flex;
  padding: 7px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
}

.donor-filter-content-sub-title {
  display: flex;
  font-size: 10px;
  color: var(--gray-gray-400, #6b7280);
  font-family: Inter;
  font-weight: 500;
}

.search-and-filter-container {
  display: flex;
  gap: 10px;
}

.add-dnr-search {
  height: 48px !important;
}

.input-daterange {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.more-info-icon {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

/* @media only screen and (min-width: 1600px) {
    .dashboard_main {
        width: calc(100% - 230px);
    }
} */
.input-daterange {
  display: flex;
  margin: 10px 5px;
  gap: 5px;
}

.hide_scroll {
  overflow: auto;
}

.hide_scroll::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 1250px) {
}

@media only screen and (max-width: 580px) {
  .tenant-search-select {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    column-gap: 1rem;
  }

  /* .search-container, .select-tenant-type{
        padding: 0rem !important;
    } */
  .select-tenant-type {
    padding: 0rem !important;
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 520px) {
  .search-container-tenant {
    width: 100% !important;
  }

  .search-container,
  .select-tenant-type {
    width: 100%;
  }

  .search-and-filter-container {
    flex-direction: column;
  }
}

.ag {
  position: absolute;
  z-index: 2;
}

.more-option-container {
  position: relative;
}

.alldnr-moreoptions-container {
  position: absolute;
  z-index: 2;
  background-color: #e7f8f7;
  border: 2px gray;
  border-radius: 8px;
}

.white-background {
  background: #ffffff;
}

.a {
  background: var(--grey-grey-4, #fbfbfb);
}

@media only screen and (min-width: 576px) {
  .freez-column {
    position: sticky;
    right: 0;
    z-index: 2;
  }
}

.hoverWhite:hover {
  color: #ffffff;
}

.donor-name {
  color: var(--tints-shades-p-light-10, #303f9f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.filter-ative {
  border: 1px solid var(--ehr-secondary-d-0, #214f8a);
}

.fltr-container {
  display: flex;
  height: 450px;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  background: #fff;
}
.mx-widthh {
  max-width: 575px;
}

.fltraction {
  justify-content: flex-end;
  display: flex;
  /* flex-direction: row-reverse; */
  gap: 24px;
  width: 100%;
  /* padding-bottom: 24px; */
}
.fltrlabel-div {
  width: 150px;
  margin-top: 5px;
}

@media only screen and (max-width: 770px) {
  .inpt {
    margin-bottom: 10px;
  }
}

.filter-tab-cont {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.filter-tab-div {
  margin-top: 5px;
  display: flex;
  padding: 4px 10px;
  align-items: flex-start;
  gap: 10px;
  align-items: center;
  border-radius: 4px;
  background: var(--ehr-secondary-l-9, #e9edf3);
}
