.profile-section {
  height: auto;
  border-radius: 8px;
  padding: 16px;
}

.profile-section-item {
  /* border: 2px solid red; */
  width: 100%;
  padding: 0;
  margin: 1rem auto;
  margin-top: 0rem;
}

.profile-tabs {
  display: flex;
  color: #90a7c5;
  border-bottom: 1px solid #a6b9d0;
}

.donor-tabs {
  display: flex;
  color: #214f8a;
}

.profile-tab-item {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  text-transform: none;
}

.profile-tabs > .active {
  border-bottom: 2px solid #214f8a;
  color: #214f8a;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-header-heading {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  width: 100%;
}

.profile-header-actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  /* border: 2px solid rebeccapurple; */
  width: 100%;
}

.profile-info {
  display: grid;
  /* grid-template-columns: 1fr 5fr; */
}

.profile-image-section {
  margin: 0px auto;
}

.profile-picc{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pic-actions {
  text-align: center;
  margin: 1rem auto 0 auto;
}

.pic-actions > p {
  margin: 0;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  cursor: pointer;
}

.edit-upload {
  color: #214f8a;
}

.trail{
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  font-family: Inter;
  color: var(--ehr-secondary-d-0, #214f8a);
}

.edit-profile{
    position: absolute;
    right: 10px;
}

.pic-active {
  color: #248303;
}

.profile-pic {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.profile-detail {
  width: 100%;
}

.detail-section {
  margin-bottom: 1rem;
}

.dob_input {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.detail-header {
  background: #f2f9f7;
  border-radius: 8px;
  padding: 8px;

  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;

  color: #214f8a;
}

.detail-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.grid-item {
  padding: auto;
}

.item-header {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  padding: 0;
  margin: 0;
}

.item-value {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 1500px) {
  .detail-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1100px) {
  .detail-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .profile-section-item.profile-info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }
}

@media only screen and (max-width: 520px) {
  .detail-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

/*  body  */
.donation-history-status-header {
  padding: 16px;
  border-radius: 8px;
  border: 0px dashed var(--ehr-secondary-l-0, #214f8a);
  background: #dcf4ed;
}

.dhs-heading {
  color: var(--ehr-secondary-d-0, #214f8a);
  /* Heading / H4 */
  font-family: Inter;
  margin-bottom: 5px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.dhs-section {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.dhs-grid-item {
  width: 182px;
}

.dhs-grid-item_act {
  width: 167px;
}

.dhs-item-header {
  color: var(--gray-gray-700, #1f2937);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 171.429% */
  margin: 0;
  padding: 0;
}

.dhs-item-value {
  color: var(--ehr-secondary-d-6, #07101c);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}

.abort {
  color: #fd1d1d;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.pending {
  color: var(--ehr-secondary-d-0, #214f8a);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}

.trail-subheading{
  font-size: 14px;
  font-weight: 600;
  color:#214F8A;
}

.trail-small{
  font-size: 12px;
    font-weight: 400;
    color: #9b9a9a;
    margin: 2px 0px 3px 0px;
}
.cont-heading{
  font-size: 14px;
  font-weight: 600;
  color:var(--gray-gray-400);
}
.shadowCell{
  box-shadow: 0px 4px 24px 10px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.profile-description {
  flex-shrink: 0;
  width: 100%;
  padding: 10px 10px;
  border-radius: 8px;
  background: #f2f9f7;
  position: relative;
}
.profile-description1 {
  flex-shrink: 0;
  width: 100%;
  padding: 10px 10px;
  border-radius: 8px;
  box-shadow: 5px 5px 24px #3f51b51a;
  position: relative;
  margin-bottom: 10px;
}


.inner-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.bag-wrap{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    padding:13px 0;
}

.flex-bag{
  width:33%;
}

.pd-ib-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pd-ib-item1 {
  width: 100%;
  display: flex;
  justify-content:flex-start;
  gap: 10px;
}
.filterdate{
  outline: 0;
  background:#f5f5f5;
  border: 1px solid #cfd8dc;
  color: #777676;
  height:42px;
  padding: 0 5px;
  border-radius:8px;
}

/* Style the select element */
.filterdate {
  padding: 5px;
  border-radius: 5px; 
  border: 1px solid #ccc; 
  background-color: #f9f9f9; 
  color: #333; 
  font-size: 14px; 
}

.filterdate option {
  padding: 10px; 
  color: var(--ehr-secondary-d-6, #07101c);
  border-radius:8px;
}

.filterdate option:hover {
  background-color: rgba(0, 0, 0, 0.04);
  color: var(--ehr-secondary-d-6, #07101c);
}

.filterdate option:checked {
  background-color: darkgray; 
  color: var(--ehr-secondary-d-6, #07101c);
}

/* Optional: Add a border-radius to the select element (not supported in all browsers) */
.filterdate {
  border-radius: 5px;
  overflow: hidden; /* Ensures that the border-radius is visible */
}


.general-info{
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  color:var(--gray-gray-400);
  line-height:24px;
}
.general-black{
  color: var(--ehr-secondary-d-6, #07101c);
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.general-green1{
  color: #0ae872;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}


.pic-name {
  color: #303F9F;
  font-family: Inter;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600;
}

.pic-subname {
  color: #07101c;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 10px;
}

.donation-history-table {
  vertical-align: middle !important;
}
.donation-history-table tbody tr td {
  vertical-align: middle !important;
}

.colorside{
  position: absolute;
  left: 10px;
  top: 90px;
  width: 130px;
}
.arrow{
  position: absolute;
  left: 12px;
  top: 85px;
  width: 40px;
  background: #fff;
  padding: 12px 10px;
  border-radius: 50%;
  z-index: 9;
}
.poll{
  position: absolute;
  left: 34px;
  top: 0px;
}
.dott{
  width: 13px;
  position: absolute;
  left: 29px;
  top: -10px;
  border: 4px solid rgba(33, 83, 139, 1);
  border-radius: 50%;

}
.colorround{
  position: absolute;
  width: 45px;
  left: 10px;
  top: 83px;
}
.date{
  position: absolute;
    left: 54px;
    top: 96px;
    z-index: 10;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    font-family: Inter;
    color: #fff;
}

.select_otp {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  gap: 2rem;
}

.select_radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select_radio p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  height: 100%;
  font-size: medium;
  font-weight: 700 !important;
}


.rod-flag{
  position: absolute;
  max-width: 9px;
  height:100%;
  width: auto;
  margin-left: 8px;
}
.date-flag{
  width: 113px;
  position: absolute;
  left: 0;
  top: 12px;
}
.profile-subheading{
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  font-family: Inter;
  flex-wrap: wrap;
  color: var(--ehr-secondary-l-3);
}
.profile-heading{
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: Inter;
  flex-wrap: wrap;
  color: var(--gray-gray-400);
}
.profile-date{
  position: absolute;
  left: 15px;
  top: 17px;
  z-index: 10;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  font-family: Inter;
  color:#fff;
}