/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* .col-md-6 {
  padding-right: 10px !important;
  padding-left: 10px !important;
} */

.registeration-slef {
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.registeration-slef h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.5rem;
  /* or 133% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Primary/Black */

  color: #000000;
}

.searchCustomm {
  width: 100%;
  z-index: 99;
  min-height: 7rem;
  max-height: 12rem;
  overflow-y: scroll;
  background: white;
  border-radius: 0rem 0rem 1rem 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 5px;
}

.searchCustomm .nav-item {
  padding: 0px 0.5rem !important;
  border-radius: 8px;
  background: #214f8a40;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  min-height: 2.5rem;
}

.searchCustomm .nav-item .nav-profile-textt {
  padding-top: 5px;
}
.searchCustomm .nav-item .nav-profile-textt h1 {
  background: #e5e7eb;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  color: black;
  justify-content: center;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1;
  text-transform: capitalize;
}

.searchCustomm p {
  color: black;
  width: 100%;
  padding-left: 0.5rem;
}

.registeration-slef p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1rem;
  text-align: center;
  color: #6b7280;
  min-height: max-content;
}

.emty_cell {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.abha_input-num {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;
  background: #f5f5f5;
  border: 1px solid #d1d5db;
  border-radius: 8px;

  width: 96%;
}

.input_new_design {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  background: #f5f5f5;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  width: 100%;
}

.row,
.pills-vertical,
.form-wizard.form-wizard-vertical .container {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px !important;
  margin-left: 0px !important;
  width: 100% !important;
}

.otp-input {
  /* border: 2px solid black; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: auto;
}

.otp-input > input {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  background: #f5f5f5;

  border: 1px solid #d1d5db;
  border-radius: 8px;
}

.abha_create {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.abha_create_inner {
  width: 95%;
}

.abha__top {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.choice-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent;
  /* Shadow/Shadow-1 */

  box-shadow: 5px 5px 24px rgba(63, 81, 181, 0.1);
  border-radius: 11.1736px !important;
  /* cursor: pointer; */
}
.choice-container:hover {
  opacity: 0.7;
}
.active p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16.7603px;
  line-height: 22px;
  /* color: #214f8a; */
}

.choice-container.active {
  border: 1.4px solid;
  /* border-image: linear-gradient(90.26deg, #214F89 0.17%, #12BCAB 99.77%); */
  /* border-image-slice: 1; */

  border-radius: 8px;
  border: double 1px transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(90.26deg, #214f89 0.17%, #12bcab 99.77%);
  background-origin: border-box;
  background-clip: content-box, border-box;

  box-shadow: none;
}

/* iframe {
  position: static !important;
} */

.terms_p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}

.flex__box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp {
  width: 70%;
}

.donor_add {
  /* min-height: 70vh; */
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem; */
  width: 100%;
}
.center-registeration-icon{
  display: flex;
  width: 100%;
  align-items: center;
}

.certificate_ img {
  padding: 0.4rem;
  height: 60%;
  width: 70%;
}

.inputStyle {
  width: 50% !important;
  height: 40px;
  border-radius: 7px;
  border: 0px;
  margin-right: 8px;
  background: #dddddd;
  font-size: 18px;
}

.resend {
  text-decoration: underline;
  font-size: 14px;
}

.otpElements {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding-left: 18px;
  justify-content: center;
}

.card::-webkit-scrollbar {
  display: none;
}

.resendOtp {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resendOtp p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #4d72a1;
}

.choice-container img {
  height: 65%;
  width: 75%;
  object-fit: contain;
  margin: 2px;
}

.choice-container p {
  text-align: center;
}

.error-box {
  width: 90%;
  margin: auto;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .card-body {
    padding: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .abha_create_inner {
    width: 95%;
  }

  .choice-container {
    height: 80px;
    width: 105px;
    margin: 0.2rem;
  }

  .choice-container p {
    font-size: 11px;
    line-height: 10px;
  }

  .otp-input > input {
    width: 35px;
    height: 35px;
  }
}

/* Small devices (small phones, 300px and up) */
@media only screen and (min-width: 300px) {
  .abha_create_inner {
    width: 98%;
  }

  .choice-container {
    height: 86px;
    width: 93px;
    margin: 5px;
  }

  .choice-container p {
    font-size: 10px;
    line-height: 10px;
  }

  .reg-box {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    margin: 2rem auto;
    gap: 10px;

    border: 1px dashed #214f8a !important;
    border-radius: 8px;

    flex: none;
    order: 1;
    flex-grow: 0;

    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .otp-input {
    width: 95%;
  }
}

.btn-gradient-custom {
  outline: none;
  background: linear-gradient(90.26deg, #214f89 0.17%, #12bcab 99.77%);
  color: #fff;
  border: none;
}

.screenTop {
  justify-content: flex-start;
  align-items: center;
}

.border__left {
  border-left: 1px solid #9ca3af;
}

.screenTop div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer__self {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100% !important;
  min-height: 1rem;
  background: #edeff3;
  border-top: 1px solid #dee1e9;
  padding: 5px 1rem;
  transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  font-size: calc(0.875rem - 0.05rem);
  font-family: "Ubuntu", sans-serif;
}

.gradient_back {
  width: 98%;
  background: linear-gradient(to right, #214f89, #12bcab);
}

.screenTop img {
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 1rem;
  border-radius: 8px;
  gap: 8px;
}

.screenTop-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 24px;
  color: #1f2937;
}

.screenTop-title2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  color: #1f2937;
}

.screenTop p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 10px;
  color: #6b7280;
}

.screen__top {
  background: #f2f9f7;
  padding: 1rem 0rem 1rem 0rem;
  border-radius: 0.5rem;
}

.bloodbank_action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.bloodbank_actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.stock_head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 10px;
}

.bloodbank_action div {
  flex-wrap: wrap;
}

.stock_head,
.bloodbank_action p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1rem;
  color: #07101c;
}

/* progress step form css starts */

.form {
  display: flex;
  justify-content: flex-start;
  align-items: start;
}

.form .left-side {
  width: 15%;
  background-color: #f2f9f7;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 15px 30px;
  box-sizing: border-box;
}

.progress-top-side {
  background-color: #f2f9f7;
  height: 100%;
  padding: 15px 30px;
  box-sizing: border-box;
  border-radius: 0.5rem;
}

.form .right-side {
  width: 85%;
  height: 100%;
  padding-left: 1rem;
  box-sizing: border-box;
}

.question_lebel {
  width: 48%;
  padding-right: 1rem;
}

.answer_lebel {
  width: 20%;
}

.row,
.pills-vertical,
.form-wizard.form-wizard-vertical .container {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px !important;
  margin-left: 0px !important;
  width: 100% !important;
  justify-content: flex-start;
  align-items: flex-start;
}

.col-form-label {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1;
}

.form .right-side .mx-auto .disease-list {
  width: 100% !important;
  column-gap: 8% !important;
  justify-content: flex-start !important;
  grid-template-columns: repeat(4, 1fr);
}

.disease-list {
  grid-template-columns: repeat(4, 1fr) !important;
}

.form .right-side .mx-auto .disease-list .disease-item {
  justify-content: flex-start !important;
}

.disease-item {
  width: 100%;
  height: max-content;
  /* border: 2px solid rebeccapurple; */
}

.disease-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr) !important;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  grid-column-gap: 10%;
  column-gap: 10%;
  /* border: 2px solid rebeccapurple; */
}

.progress-custom {
  padding-left: 0rem;
}

.progress-custom li {
  position: relative;
  margin-left: 40px;
  margin-top: 50px;
  counter-increment: container 1;
  color: #000 !important;
}

.progress-custom li.active {
  color: #214f8a !important;
}

.progress-custom {
  list-style: none;
  font-size: 13px;
  font-weight: 700;
  counter-reset: container 0;
}

.progress-custom li {
  position: relative;
  margin-left: 40px;
  margin-top: 50px;
  counter-increment: container 1;
  color: #4f6581;
}

.progress-custom li::before {
  content: counter(container);
  text-align: center;
  position: absolute;
  line-height: 34px;
  height: 34px;
  width: 34px;
  border: 1px solid #a6b9d0;
  border-radius: 50%;
  left: -40px;
  top: -5px;
  z-index: 10;
  background-color: #304767;
  color: #fff;
}

.progress-custom li::before {
  line-height: 34px;
  height: 34px;
  width: 34px;
  background-color: #a6b9d0 !important;
  font-size: 1.5rem;
}

.progress-custom li {
  margin-top: 140px;
  padding-left: 0.8rem;
  font-size: 1rem;
}

.progress-custom li:first-child {
  margin-top: 20px;
}

.progress-custom li.active::before {
  line-height: 34px;
  height: 34px;
  width: 34px;
  background-color: #214f8a !important;
}

.bloodbank_action p {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #07101c;
}

.search-container,
.select-tenant-type {
  height: 37px !important;
  padding: 0rem 1rem !important;
}

.search-input {
  width: 100%;
  min-width: max-content;
}

input.form-control {
  background: #f5f5f5;
}

select.form-control {
  color: #000 !important;
  appearance: none;
  position: relative;
  appearance: none;
}

select.form-control {
  content: "";
  color: #000 !important;
  background: #f5f5f5 url('/public/assets/images/arrow.svg') no-repeat right 10px
    center/24px 24px;
}

/* .select-tenant-type{
  display: flex;
  width: max-content !important;
  margin: 0 !important;
} */

.main_profile .add_info .info_1 .info_col {
  grid-gap: 0.6rem;
}

.progress-custom li::after {
  content: "";
  position: absolute;
  height: 90px;
  width: 2px;
  background-color: #4f6581;
  z-index: 1;
  left: -30px;
  top: -70px;
}

.progress-custom li.active::after {
  background-color: #214f8a;
}

.progress-custom li::after {
  content: "";
  position: absolute;
  height: 160px;
  width: 2px;
  background-color: #4f6581;
  z-index: 1;
  left: -24px;
  top: -160px;
}

.progress-custom li.active::after {
  background-color: #214f8a;
}

.progress-custom li:first-child:after {
  display: none;
}

.progress-custom li.active::before {
  color: #fff;
  border: 1px solid #fff;
}

.progress-custom li.active {
  color: #214f8a;
}

.d-none {
  display: none;
}

/* progress step form css ends */

.div_label {
  position: absolute;
  top: -8.5px;
  left: 25px;
  padding: 0px 5px;
  background: #fff;
}

.div_label_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #214f8a;
}

.row .col label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #07101c;
}

.row .col h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #07101c;
}

.banner {
  height: 11rem;
  margin: 1rem 1.2rem;
  border-radius: 13px;
}

.col_title {
  font-size: 18px !important;
}

@media only screen and (min-width: 500px) {
  .otp-input {
    width: 80%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .abha_create_inner {
    width: 95%;
  }

  .choice-container {
    height: 100px;
    width: 115px;
    margin: 0.3rem;
  }

  .choice-container p {
    font-size: 12px;
    line-height: 11px;
  }

  .otp-input {
    width: 75%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .abha_create_inner {
    width: 87%;
  }

  /* .choice-container {
    height: 110px;
    width: 135px;
    margin: 0.35rem;
  } */

  .choice-container p {
    font-size: 12px;
    line-height: 12px;
  }

  .otp-input {
    width: 65%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .abha_create_inner {
    width: 80%;
  }

  .choice-container {
    height: 120px;
    width: 145px;
    margin: 0.4rem;
  }

  .choice-container p {
    font-size: 13px;
    line-height: 13px;
  }

  /*  .donor_add {
    min-height: 80vh; 
  }
*/
  .otp-input {
    width: 55%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .abha_create_inner {
    width: 75%;
  }

  .choice-container {
    height: 150px;
    width: 187px;
  }

  .choice-container p {
    font-size: 14px;
    line-height: 14px;
  }

  /*  .donor_add {
   min-height: 72vh; 
  }
*/
  .otp-input {
    width: 50%;
  }
}

@media only screen and (min-width: 1400px) {
  .abha_create_inner {
    width: 60%;
  }

  .otp-input {
    width: 40%;
  }

  /* .choice-container {
    height: 160px;
    width: 197px;
  } */
}

/* donor inventory css start here */
.search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;

  width: 450px;
  height: 37px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  background: #f5f5f5;
  /* Grey/Blue-Grey #9 */

  border: 1px solid #cfd8dc;
  border-radius: 8px;
  padding: 1rem;
}

.search-container > input {
  background: transparent !important;
  border: none !important;
  border-radius: 0px !important;
  /*width: 50%;
  */
  padding: 0 !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  height: 100%;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 0rem;
  margin-bottom: 0rem;
  font-size: 0.875rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
}

.breadcrumb a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Primary/Primary-Dark */

  color: #00303d;

  /* Inside auto layout */

  flex: none;
  order: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "";
  background-image: url('/public/assets/images/bread-arrow.svg');
  height: 4px;
  width: 4px;
  display: block;
  padding: 0.8rem;
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.navbar-menu-wrapper {
  height: 4vh !important;
  min-height: 40px !important;
  /* border: 2px solid rebeccapurple; */
}

.stretch-card,
.table_wrap {
  width: 100% !important;
  padding: 0px;
}

.card .card-body {
  padding: 1rem !important;
}

.table_wrap .card .card-body {
  padding: 1rem;
  height: 79vh;
}

.col,
.form-wizard .container ol.progtrckr li {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.screen__top {
  padding: 0.8rem 0rem;
}

.screen__top .col {
  padding: 0rem 0.8rem;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #ebedf2;
  font-size: 0.9rem;
}

.react_table thead th {
  vertical-align: middle !important;
  border-bottom: 0px solid #ebedf2;
  font-size: 0.9rem;
}

.table_header {
  border-radius: 28px 28px 0px 0px;
  -webkit-border-radius: 28px 28px 0px 0px;
  -moz-border-radius: 28px 28px 0px 0px;
  -ms-border-radius: 28px 28px 0px 0px;
  -o-border-radius: 28px 28px 0px 0px;
}

.table_header th {
  vertical-align: middle !important;
}

.screenTop p {
  margin: 0;
}

.donor_action {
  justify-content: flex-start !important;
}

.donor_action img {
  padding-right: 1rem;
  height: auto !important;
  width: auto !important;
}

.breadcrumb .breadcrumb-item {
  display: flex;
  align-items: center;
}

.breadcrumb a {
  color: #07101c !important;
}

.breadcrumb-item.active {
  color: #6b7280;
}

.donor-inventory-search {
  margin-bottom: 1rem !important;
}

.screen__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-content {
  padding-top: 76px;
}

.addNewData {
  padding: 0rem 0rem;
}

.addNewData p {
  margin: 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* or 159% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Gray/Gray-400 */

  color: #6b7280;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.form-wizard .container > div:not(.content) > button,
.swal2-modal .swal2-buttonswrapper .swal2-styled,
.swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-confirm,
.swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-cancel,
.swal2-modal .swal2-actions .swal2-styled,
.fc button {
  font-size: 1rem !important;
  height: 48px;
}

.add_data {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;

  /* Ehr/Secondary/l-8 */

  background: #d3dce8;
  border-radius: 7.42727px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 171% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Secondary */

  color: #214f8a;

  /* Inside auto layout */

  flex: none;
  flex-grow: 0;
}

.stretch-card > .card {
  height: 100vh;
}

.modal-input-container {
  box-sizing: border-box;

  /* Secondary */

  border: 1px dashed #214f8a;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 1rem;
  gap: 1rem;
}

.modal-input-container {
  display: flex;
  grid-template-columns: 1fr 1fr;
  border: 1px dashed #214f8a;
  border-radius: 8px;
}

.modal-input-item {
  /* border: 2px solid rebeccapurple; */
  display: flex;
  justify-content: flex-start;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0.9375rem;
  gap: 1rem;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
}

label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* Gray/Gray-700 */

  color: #1f2937;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.modal-input-item {
  min-width: 256px;
}

.modal-input-item input,
.modal-input-item select {
  margin-bottom: 0px !important;

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;

  width: 256px;
  height: 40px;

  /* Gray/Gray-200 */

  border: 1px solid #d1d5db;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  position: relative;
}

.modal-input-item select:after {
  content: "";
  position: absolute;
  background-image: url('/public/assets/images/arrow-down.svg');
  height: 18px;
  width: 18px;
  display: block;
  padding: 0.8rem;
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

/* .mid_menu::-webkit-scrollbar {
  width: 4px;
} */

/* Track */
/* .mid_menu::-webkit-scrollbar-track {
  background: transparent;
} */

/* Handle */
/* .mid_menu::-webkit-scrollbar-thumb {
  background: #214f8a;
} */

/* Handle on hover */
/* .mid_menu::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

/* side menu css start here */
.nav.mid_menu {
  height: calc(100vh - 120px) !important;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}
.sidebar-list-items {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}




.nav.mid_menu.footer_menu {
  height: calc(100vh - 120px) !important;
  overflow: hidden;
  overflow-y: scroll !important;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.managment {
  display: block;
}

.management_menu {
  height: 0px;
}

.mdi:before,
.mdi-set {
  font-size: 2rem !important;
}

.footer-note {
  padding: 1rem !important;
  padding-bottom: 0.2rem !important;
}

.management_menu.drop-down-clicked {
  height: auto;
}

.disease-list {
  grid-template-columns: repeat(4, 1fr) !important;
}

.page-body-wrapper {
  height: 100vh;
  overflow: hidden;
}

.card .card-body {
  overflow: auto;
}

/* responsive media start here */
.profile-col {
  width: 20%;
  padding: 0rem 1rem;
}

.modal-input-item {
  /* border: 2px solid rebeccapurple; */
  display: flex;
  justify-content: flex-start !important;
}

.gray_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  background: #ecf4ff;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.9;
  border: none;
  border: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  text-align: center;
  color: #214f8a !important;
}

.gray_btn:hover {
  color: #214f8a !important;
}

.modal-action-container img {
  padding: 0.5rem 0rem;
}

.addNewComponent {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #009283;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 13%;
}

.modal-content {
  width: 670px !important;
  max-height: 70vh !important;
  overflow-y: scroll !important;
}

.bloodbank_action {
  padding: 1rem 0rem;
}

.swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-confirm,
.swal2-modal .swal2-buttonswrapper .swal2-styled.swal2-cancel,
.swal2-modal .swal2-actions .swal2-styled,
.fc button {
  font-size: 1rem !important;
  white-space: nowrap !important;
}

.bloodbank_action p {
  margin-bottom: 0rem;
}

.modal-header > img {
  height: 40px;
}

.modal-header > img {
  width: 40px !important;
}

.double_btn {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.modal-header {
  position: sticky !important;
  top: 0 !important;
  z-index: 999 !important;
  background-color: #fff;
}

.modal-footer {
  position: sticky !important;
  bottom: 0 !important;
  background-color: #fff !important;
}

/* .screen__top .profile-col:first-child{
  width: 40%;
}
.profile-col{
  width: 30%;
} */
.stretch-card > .card {
  height: 100% !important;
  overflow-x: hidden !important;
  padding: 0rem 0rem;
}

.form-group label {
  font-size: 1rem;
  line-height: 1.8;
}

.result_question {
  width: 80%;
}

/* responsive media start here */

@media only screen and (max-width: 2200px) {
  .form .left-side {
    width: 20%;
  }

  .form .right-side {
    width: 80%;
  }
}

@media only screen and (max-width: 2200px) {
  .form .left-side {
    width: 25%;
  }

  .form .right-side {
    width: 75%;
  }
}

/* side-card css -> Arpit */

.combo-container {
  /* border: 2px solid red; */
  display: flex;
  padding: 1rem 0rem;
}

.combo-container > .table-responsive {
  /* border: 2px solid rebeccapurple; */

  margin-left: 0;

  box-shadow: 0px 4px 24px 10px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.searchWidth{
  width: 400px;
}

@media screen and (max-width: 768px) {
  .searchWidth{
    width: 400px;
    margin-top:5px;
  }
  
}
@media screen and (max-width: 400px) {
  .searchWidth{
    width:380px;
    margin-top:5px;
  }
  
}

.side-card {
  /* border: 2px solid rebeccapurple; */
  width: 40%;

  padding: 1rem;
  margin-right: 0.5rem;

  box-shadow: 0px 4px 24px 10px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.side-card > .side-card-heading {
  font-family: Inter;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}

.card_mid_data {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 1rem;
}

.card_mid_data .side-card-item {
  width: 50%;
  padding: 1rem 0rem;
}

.card_mid_data .side-card-item p {
  margin: 0rem;
}

.card_mid_data .side-card-item p.item-header {
  margin: 0rem;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  padding: 0;
  margin: 0;
}

.side-card > .side-card-item > .item-head {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.side-card > .side-card-item > .item-value {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.side-card > .side-card-btn {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}

/* banner-css -> Arpit */
.banner-body {
  color: #ffffff;
  border-radius: 8px;
  padding: 0.2rem;
  margin: 1rem 0rem;
  background-repeat: no-repeat;
}

.banner-body > h1 {
  font-family: Inter;
  font-size: 80px;
  font-style: italic;
  font-weight: 700;
  line-height: 97px;
  letter-spacing: 0px;
  text-align: center;
}

.banner-body > p {
  font-family: Inter;
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
  line-height: 1.7;
  letter-spacing: 0px;
  text-align: center;
}

.donate-now {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.donate-now li {
  float: left;
  margin: 0 5px 0 0;
  min-width: 4.5rem;
  width: 6rem;
  height: 3rem;
  position: relative;
  cursor: pointer;
}

.donate-now label,
.donate-now input {
  display: block;
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
}
.donate-now input[type="radio"] {
  opacity: 0.01;
  z-index: 100;
  width: 0;
  height: 0;
}
.donate-now input[type="radio"]:focus + label {
  outline: solid 1px black;
}
.donate-now input[type="radio"]:checked + label,
.Checked + label {
  background: linear-gradient(90.26deg, #214f89 0.17%, #12bcab 99.77%);
  color: #fff;
}
.donate-now label {
  padding: 5px 2px;
  border: 1px solid #ccc;
  cursor: pointer;
  background: #f5f5f5;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  border-radius: 8px;
}
.donate-now label:hover {
  background: #ddd;
}

/* 
.gender_radioo {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.gender_radioo span input,
.gender_radioo span span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.gender_radioo span input[type="radio"],
.gender_radioo span span {
  opacity: 0.01;
  z-index: 100;
  width: 0;
  height: 0;
}

.gender_radioo input[type="radio"]:focus + label {
  outline: solid 1px black;
}

.gender_radioo input[type="radio"]:checked + label,
.Checked + label {
  background: linear-gradient(90.26deg, #214f89 0.17%, #12bcab 99.77%);
  color: #fff;
}

.gender_radioo label {
  padding: 8px;
  border: 1px solid #ccc;
  cursor: pointer;
  background: #f5f5f5;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;

}

.gender_radioo label:hover {
  background: #ddd;
} */

/*Donor details*/
label[for="Husband"] {
  width: 90px;
}
.flex {
  display: flex;
}

@media only screen and (max-width: 1600px) {
  .disease-list {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  .profile-col {
    width: 25%;
    padding: 1rem;
    border: none;
  }

  .main-content {
    width: 100%;
    padding-top: 70px;
  }

  .banner-body > h1 {
    font-size: 70px;
    line-height: 1.8;
  }
}

@media only screen and (max-width: 1500px) {
  .container_wraper .main-content {
    width: 100%;
  }

  .banner-body > h1 {
    font-size: 65px;
    line-height: 1.8;
  }

  .detail-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1400px) {
  .card-body {
    padding: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .banner-body > h1 {
    font-size: 60px;
    line-height: 1.8;
  }

  .profile-col {
    width: 25%;
  }

  .border__left {
    border-left: 0px solid #9ca3af;
  }
}

@media only screen and (max-width: 1300px) {
  .form {
    flex-direction: column;
  }

  .form .left-side {
    width: 100%;
  }

  .banner-body > h1 {
    font-size: 55px;
    line-height: 1.8;
  }

  .form .right-side {
    width: 100%;
  }

  .progress-custom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .progress-custom li:first-child {
    margin-top: 50px;
  }

  .progress-custom li {
    margin-left: 0px;
    margin-top: 50px;
  }

  .progress-custom li::before {
    top: -45px;
    left: 40%;
  }

  .progress-custom li::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 440px;
    background-color: #4f6581;
    z-index: 1;
    left: -200%;
    top: -28px;
  }

  .banner-body > p {
    font-size: 22px;
  }
}

@media only screen and (max-width: 1200px) {
  .combo-container {
    /* border: 2px solid red; */
    display: flex;
    padding: 1rem 0rem;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }

  .side-card {
    /* border: 2px solid rebeccapurple; */
    width: 100%;
  }

  .side-card-item {
    width: 25%;
  }

  .container_left {
    width: 250px;
    margin: 16px;
  }

  .container_right {
    width: calc(100% - 250px);
  }

  .progress-custom li::after {
    width: 400px !important;
    left: -170%;
  }

  .profile-col {
    width: 33.33%;
  }

  .screen__top {
    justify-content: end;
  }

  .main-content {
    width: 69vw;
    padding-top: 70px !important;
  }

  .main-content {
    width: 100vw;
    padding-top: 46px;
  }

  .sidebar-collapse .main-content {
    width: 100vw;
    padding-top: 70px !important;
  }

  .container_wraper {
    gap: 0rem;
  }
}

@media only screen and (max-width: 1150px) {
  .main-content {
    width: 73vw;
    padding-top: 46px;
  }

  .banner-body > p {
    font-size: 20px;
  }

  .banner-body > h1 {
    font-size: 50px;
    line-height: 1.8;
  }
}

@media only screen and (max-width: 1100px) {
  .main-content {
    width: 70vw;
    padding-top: 60px !important;
  }

  .progress-custom li::after {
    width: 320px !important;
    left: -140%;
  }

  .banner-body > h1 {
    font-size: 45px;
    line-height: 1.8;
  }
}

@media only screen and (max-width: 1024px) {
  .progress-custom li::after {
    width: 265px !important;
    left: -105%;
  }

  .sidebar-collapse .progress-custom li::after {
    width: 395px !important;
    left: -175%;
  }

  .banner-body > p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .sidebar-collapse .container_right {
    width: calc(100% - 80px) !important;
  }

  .container_right {
    padding: 1rem 0rem;
  }

  .container_left {
    width: 270px;
    margin: 16px;
  }

  .main-content {
    width: 68vw;
    padding-top: 46px;
  }

  .sidebar-collapse .main-content {
    width: 85vw;
    padding-top: 70px;
  }

  .banner-body > h1 {
    font-size: 40px;
    line-height: 1.8;
  }

  /* .double_btn{
flex-direction: column;
} */
  .bloodbank_action {
    padding: 1rem 0rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: self-start;
    gap: 1rem;
  }
  
  .bloodbank_actions {
    padding: 1rem 0rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: self-start;
    gap: 1rem;
  }

  .choice-container {
    height: 110px;
    width: 200px;
    margin: 0.35rem;
  }

  .banner-body > p {
    font-size: 16px;
  }
}
.Donor-details-sections {
  margin-top: 20px;
  margin-bottom: 10px;
}
.no-top-margin {
  margin-top: 0;
}
.donor-details-container {
  overflow: hidden;
}

@media only screen and (max-width: 912px) {
  .progress-custom li::after {
    width: 207px !important;
    left: -70%;
  }

  .sidebar-collapse .progress-custom li::after {
    width: 301px !important;
    left: -127%;
  }

}

@media only screen and (max-width: 820px) {
  .progress-custom li::after {
    width: 137px !important;
    left: -36%;
  }



  .sidebar-collapse .progress-custom li::after {
    width: 249px !important;
    left: -97%;
  }
}

@media only screen and (max-width: 768px) {
  .container_wraper .main-content {
    padding: 1rem;
    padding-top: 60px;
  }

  .question_lebel {
    width: 100%;
    padding-right: 1rem;
  }

  .banner-body > p {
    font-size: 14px;
  }

  .banner-body > h1 {
    font-size: 35px;
    line-height: 1.8;
  }

  .progress-custom li::after {
    width: 108px !important;
    left: -32%;
  }

  .sidebar-collapse .progress-custom li::after {
    width: 211px !important;
    left: -75%;
  }

  .modal-title {
    font-size: 20px !important;
  }

  .modal-input-container {
    display: flex !important;
    flex-direction: column !important;
    width: 90% !important;
  }

  .profile-col {
    width: 50%;
  }

  .screen__top {
    justify-content: flex-start;
  }

  .main-content {
    padding-top: 60px;
  }

  .sidebar-collapse .main-content {
    width: 100%;
    padding-top: 70px;
  }

  .modal-content {
    width: 500px !important;
  }
}

@media only screen and (max-width: 600px) {
  .screen__top .profile-col:first-child {
    width: 100%;
  }

  .profile-col {
    width: 50%;
  }

  /* .card_das_title {
    flex-direction: column;
  } */

  .modal-content {
    width: 400px !important;
  }

  .disease-list {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .registeration-slef p {
    text-align: left;
  }

  .registeration-slef h1 {
    text-align: left;
  }
}

@media only screen and (max-width: 520px) {
  .progress-custom li::after {
    width: 85px !important;
    left: -24%;
  }

  .disease-list {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .form .left-side {
    padding: 10px 10px;
    box-sizing: border-box;
  }

  .progress-custom li {
    text-align: center;
    line-height: 1.4rem;
  }

  .screen__top {
    justify-content: flex-start;
  }

  .modal-input-item input,
  .modal-input-item select {
    width: 206px;
    font-size: 0.8rem !important;
  }

  .donor-inventory-search {
    justify-content: space-between;
    align-items: flex-start !important;
    margin: auto;
    flex-direction: column;
    gap: 1rem;
  }

  .donor-inventory-search .search-container {
    width: 100% !important;
  }

  .modal-content {
    width: 300px !important;
  }

  .modal-input-container {
    padding: 0.5rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
}

@media only screen and (max-width: 520px) {
  .profile-col {
    width: 100%;
  }

  .screen__top {
    justify-content: flex-start;
  }
  .responsiveDiv-create-donor {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .responsiveBtn-create-donor {
    width: 90%;
    margin: 4px;
    display: block;
  }
}

@media only screen and (max-width: 400px) {
  .profile-col {
    width: 100%;
  }
  .responsiveDiv-create-donor {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .responsiveBtn-create-donor {
    width: 90%;
    margin: 4px;
    display: block;
  }
}

.hindi-label {
  margin-bottom: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
}

.form-group label {
  margin-bottom: 0;
}

.label-txt {
  font-size: 1rem;
  font-weight: 600;
}
.btn-custom-text{
  color: rgb(33, 79, 138);
  outline: none;
  background: transparent;
  border: none;
  display: flex;
  padding: 8px 12px;
  grid-gap: 8px;
  gap: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  min-width: 95px;
  font-size: 0.8rem !important;
}

.paddingnone{
  padding-left: 0rem !important;
}

.div-class {
  width: 100%;
}

/* Medium screens (min-width: 768px) */
@media (min-width: 768px) {
  .div-class {
    width: 100%;
  }
}

/* Large screens (min-width: 1024px) */
@media (min-width: 1024px) {
  .div-class {
    width: 75%; /* 3/4 equivalent */
  }
}