.loan__header thead {
  background: var(--EHR-background, #f2f9f7) !important;
}

.loan__header td {
  padding: 0.4rem !important;
}

.testing_form {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  vertical-align: bottom;
  height: 25px;
  gap: 5px;
}
.testing_form_div {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1.2rem;
}

.height_40 {
  height: 40px;
}

.testing_form_div p {
  min-width: 8rem;
  color: var(--Gray-Gray-400, #6b7280);
  text-align: center;
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.testing_form_div_view {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.2rem;
}

.testing_form_div_view h6 {
  min-width: 5rem;

  color: var(--Gray-Gray-500, #4b5563);
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.filter_transfer_search {
  min-height: 50px;
  background: #e9edf3;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  margin-top: 1rem;
}

.filter_transfer_search h5 {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #214f8a;
  margin-top: 5px;
}

.filter_transfer_search_middle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.action_tranfser img{
  max-width: 50px;
}