.main_profile {
    box-sizing: border-box;
    background: #F2F9F7;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    gap: 8px;
    /* width: calc(100% - 270px); */
}

.main_profile .profile_info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 30%;
}

.main_profile .profile_info figure {
    margin: 0rem;
}

.main_profile .profile_info figure img {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 1rem;
    border-radius: 8px;
    grid-gap: 8px;
    gap: 8px;
}

.main_profile .profile_info .info_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #1F2937;
    flex: none;
    order: 0;
    flex-grow: 0;
    gap: 1rem;
    padding-bottom: 0.5rem;
}

.main_profile .profile_info span.small_info_left {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #6B7280;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-right: 1px solid #6B7280;
    padding: 0rem;
    padding-right: 1rem;
}

.main_profile .profile_info span.small_info_right {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #6B7280;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 0rem;
    padding-left: 1rem;
}

.main_profile .add_info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    width: max-content;
}

.main_profile .add_info .info_1 {
    gap: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;

}

.main_profile .add_info .info_1 .info_col {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    /* width: 50%; */
    padding-right: 1rem;
    border-right: 1px solid #6B7280;
}

.main_profile .add_info .info_1 .info_col:last-child {
    border-right: none;
}

.main_profile .add_info .info_1 .col_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;

}

@media (max-width: 1550px) {
    .main_profile .add_info .info_1 .info_col {
        border-right: none;
    }

    .main_profile .add_info .info_1 {
        flex-wrap: wrap;
    }

    .main_profile .add_info .info_1 .info_col {
        width: 48%;
    }
}

@media (max-width: 1300px) {
    .main_profile .profile_info {
        width: 40%;
    }

    .main_profile .add_info {
        width: 60%;
    }

    .dh-profile-header-section {
        width: 150px;
        margin-top: 10px;
        border-left: 1px solid #9CA3AF;

    }

    .dh-profile-header-section-first {
        width: 150px;
        margin-top: 10px;

    }

    .dh-profile-header-section-last {
        padding: 0 12px;
        border-left: 1px solid #9CA3AF;
        /* border-right:1px solid #9CA3AF ; */
        /* width: 150px; */

        margin-top: 10px;
    }

}

@media (max-width: 1100px) {
    .main_profile .add_info .info_1 .info_col {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .main_profile .profile_info {
        width: 50%;
    }

    .main_profile .add_info {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .main_profile .profile_info {
        padding-bottom: 1rem;
    }

    .main_profile {
        flex-direction: column;
    }

    .main_profile .profile_info {
        width: 100%;
    }

    .main_profile .add_info {
        width: 100%;
    }

    .main_profile .add_info .info_1 .col_title {
        font-size: 18px;
    }

    .info_title {
        font-size: 16px;
    }

    .cal_info {
        font-size: 14px;
    }

    .dh-profile-header-section-last {
        border: none !important;
        border-right: none !important;
        width: 100%;
    }

    .dh-profile-header-section {
        border: none !important;
        border-right: none !important;
        width: 100%;
    }
    .dh-profile-header-section-first {
        border: none !important;
        border-right: none !important;
        width: 100%;
    }

}

@media (max-width: 500px) {
    .donation-history-profile-header {
        flex-direction: column;
    }

    .d-history-profile-name {
        overflow: hidden;
    }
}





.donation-history-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.donation-history-profile-header {
    display: flex;
    padding: 10px;

    border-radius: 8px;
    background: #F2F9F7;

}

.donation-history-profile-header-section>image {
    width: 80px !important;
    height: 80px !important;
    border-radius: 8px;
}

.d-history-profile-details {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-left: 15px;
}

.d-history-profile-name {
    color: var(--tints-shades-p-light-10, #303F9F);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    overflow: hidden;
}

.d-history-profile-age {
    width: 125px;
    color: var(--gray-gray-400, #6B7280);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
}

.d-history-profile-name-view-more {
    color: var(--tints-shades-p-light-10, #303F9F);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 200% */
    text-decoration-line: underline;
    cursor: pointer;
}

.dh-profile-header-section {
    padding: 0 12px;
     border-left: 1px solid #9CA3AF; 
}
.dh-profile-header-section-first {
    padding: 0 12px;
     /* border-left: 1px solid #9CA3AF;  */
}

.dh-profile-header-section-last {
    padding-left: 12px;
     border-left: 1px solid #9CA3AF; 
}

.dh-pr-section {
    min-width: 300px;
    display: flex;
    height: 80px;
    margin-right: 10px;
    flex-wrap: nowrap;

}

.dh-profile-header-lebel {
    color: var(--gray-gray-400, #6B7280);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
}

.dh-profile-header-value {
    color: var(--gray-gray-700, #1F2937);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    display: flex;
    flex-shrink: 0;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
}

.dh-profile-header-dtls {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.pending {
    color: var(--ehr-secondary-d-0, #214F8A);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
}